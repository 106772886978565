import React from 'react'
import { Widget, WidgetTitle } from '../styled/widget'
import { ButtonLink, ButtonAnchor } from '../styled'

const RelatedPage = ({ relatedPage }) => {
  if (!relatedPage && !relatedPage.page) {
    return null
  }

  return (
    <Widget>
      {relatedPage.title && <WidgetTitle>{relatedPage.title}</WidgetTitle>}
      {relatedPage.text && <div dangerouslySetInnerHTML={{__html: relatedPage.text}} /> }
      {relatedPage.page.target !== '_blank'
        ? <ButtonLink to={relatedPage.page.url.replace(process.env.ROOT_URL, '')}>{relatedPage.button_text}</ButtonLink>
        : <ButtonAnchor target="_blank" rel="noopener" href={relatedPage.page.url}>{relatedPage.button_text}</ButtonAnchor>
      }
    </Widget>
  )
}

export default RelatedPage
