import React from 'react'
import styled from 'styled-components';
import { FullWidth } from './styled'
import { Widget } from './styled/widget'
import RelatedPage from './widgets/related-page'
import Categories from './widgets/categories'
import { device } from './helpers/constants'

const RelatedRow = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1220px;

  ${Widget} {
    &:not(:last-child) {
      margin-bottom: 4vw;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    /* width: 96vw; */

    ${Widget} {
      flex: 1;
      transition: transform 0.25s;

      &:hover {
        transform: translateY(-1.2rem)
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:first-child {
        margin-right: 2.0rem;
      }
      &:last-child {
        margin-left: 2.0rem;
      }
    }
  }
`

const Related = ({ categories, relatedPage }) => {

  return (
    <FullWidth bg="#000C26">
      <RelatedRow>
        <RelatedPage relatedPage={relatedPage} />
        <Categories categories={categories} />
      </RelatedRow>
    </FullWidth>
  )
}

export default Related
