import styled from 'styled-components'

export const Widget = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: #f7f8f9;
`

export const WidgetTitle = styled.h3`
  margin-bottom: 2rem;
`