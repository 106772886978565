import styled from 'styled-components'
import { device } from '../helpers/constants'

const Spacer = styled.div`
  margin: 10vw 0;

  @media ${device.tablet} {
    margin: 6vw 0;
  }
`

export default Spacer
