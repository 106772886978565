import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Blocks from '../components/blocks'
import WpMainMenu from '../components/wp-main-menu'
import Related from '../components/related'
import Spacer from '../components/blocks/spacer'
import JoinBeta from '../components/join-beta'

const WPPage = ({ data }) => {

  return (
    <>
      <WpMainMenu menuBg={true} />
      <Layout template="narrow_width">
        <SEO title={data.wordpressPost.title} yoastMeta={data.wordpressPost.yoast_meta} />

        <h1>{data.wordpressPost.title}</h1>

        {/* <JoinBeta /> */}

        {data.wordpressPost.acf.blocks_post && <Blocks blocks={data.wordpressPost.acf.blocks_post}/>}

        <Spacer />
        {data.allWordpressPost.edges.length ? (
          <Related
            relatedPage={data.wordpressPost.acf.related_page}
            categories={data.allWordpressPost}
          />
        ) : null}
      </Layout>
    </>
  )
}

export default WPPage

export const query = graphql`
  query($id: String!, $categories: [Int], $wpId: Int!) {

    #---- PAGE QUERY ----#
    wordpressPost(id: { eq: $id }) {
      title
      template
      wordpress_id
      acf {
        #related_page {
        #  title
        #  text
        #  button_text
        #  page {
        #    url
        #    target
        #  }
        #}
        __typename
        blocks_post {
          __typename
          ... on WordPressAcf_content_intro_text {
            id
            text
          }
          ... on WordPressAcf_content_text {
            id
            text
          }
          ... on WordPressAcf_content_embed {
            id
            embed
          }
          ... on WordPressAcf_content_image {
            id
            image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_call_to_action {
            id
          }
          ... on WordPressAcf_tip {
            id
            text
            background_color
            text_color
          }
        }
      }

      #---- YOAST QUERY ----#
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image {
          localFile {
            childImageSharp {
              fixed(quality: 90, width: 1200, height: 630) {
                src
              }
            }
          }
        }
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image {
          localFile {
            childImageSharp {
              fixed(quality: 90, width: 1024, height: 512) {
                src
              }
            }
          }
        }
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }

    #---- RELATED POST QUERY ----#
    allWordpressPost(
      filter: {
        categories: {
          in: $categories
        },
        wordpress_id: {
          ne: $wpId
        }
      },
      sort: {
        fields: date, 
        order: DESC
      }) {
      edges {
        node {
          id
          path
          title
          excerpt
        }
      }
    }
  }
`
