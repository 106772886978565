import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Widget, WidgetTitle } from '../styled/widget'

const RelatedPageLink = styled(Link)`
  text-decoration: none;
  display: block;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  > h5 {
    margin-bottom: 0;
  }

  /* p {
    margin-bottom: 0;
  } */

  &:hover > h5 {
    text-decoration: underline;
  }
`

const Categories = ({ categories }) => (
  <Widget>
    <WidgetTitle>Gerelateerde onderwerpen</WidgetTitle>
    {categories.map(edge => (
      <RelatedPageLink key={edge.node.id} to={edge.node.path}>
        <h5>{edge.node.title}</h5>
        {/* {edge.node.excerpt && <div dangerouslySetInnerHTML={{__html: edge.node.excerpt}} /> } */}
      </RelatedPageLink>
    ))}
  </Widget>
)

export default Categories
